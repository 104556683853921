import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { StaticImage } from "gatsby-plugin-image";
import Card from "../components/Card";
import Layout from "../components/layout/Layout";
import textData from "../data/testTexts";
import { SEO } from "../components/Seo";
import Button from "../components/Button";
import Accordion from "../components/Accordion";
import seoData from "../data/seoData.json";

const About = () => {
  const mapFounders = textData.founders.list.map((item) => ({
    ...item,
    title: item.name,
  }));

  return (
    <Layout>
      <div className="absolute z-0 w-[100vw] max-w-[100%]">
        <div className="bgfilter" />
        <StaticImage
          src="../images/whypeople_imagen12.jpg"
          objectFit="cover"
          alt="whypeople"
          style={{
            width: "100vw",
            maxWidth: "100%",
            position: "fixed",
            zIndex: "-5",
            minHeight: "100vh",
          }}
        />
      </div>
      <section className="py-20 lg:pb-10 relative z-[5]">
        <div className="container max-w-3xl 3xl:max-w-4xl 4xl:max-w-5xl 5xl:max-w-6xl 6xl:max-w-7xl mx-auto lg:flex px-6">
          <div className="items-center lg:text-left">
            <div className="flex flex-row">
              <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />

              <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest w-[80%]">
                {textData.who.title}
              </h1>
              <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
            </div>
            <p className="text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl mt-6 px-3 text-center">
              {textData.who.description}
            </p>
            <div className="mt-6 mx-auto text-center">
              <AnchorLink className="px-4 text-2xl  " href="#values">
                <Button className="text-sm lg:text-ml xl:text-lg 2xl:text-xl 4xl:text-2xl">
                  Nuestros valores
                </Button>
              </AnchorLink>
              <AnchorLink className="px-4 text-2xl " href="#founders">
                <Button className="my-4 text-sm lg:text-ml xl:text-lg 2xl:text-xl 4xl:text-2xl">
                  Los fundadores
                </Button>
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>

      <section id="modelo" className="py-20 lg:pb-10 relative z-[5]">
        <div className="container w-5/6 md:w-4/6 max-w-4xl mx-auto text-center">
          <h2 className="text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl font-bold text-primary-darkest">
            Nuestro modelo
          </h2>
          <p
            className=" pt-4 lg:pt-8 text-md lg:text-lg xl:text-xl 3xl:text-2xl text-justify inline-block"
            dangerouslySetInnerHTML={{ __html: textData.who.content }}
          />
        </div>
        <div id="values" />
      </section>
      <section className="my-12 py-20 lg:pb-10 lg:pt-30 relative z-[5]">
        <div className="container w-5/6 md:w-4/6 mx-auto text-center">
          <h2 className="text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl font-bold text-primary-darkest">
            {textData.values.title}
          </h2>
          <div className="flex flex-col ml:flex-row mt-12">
            {textData.values.list.map((value) => (
              <div key={value.title} className="flex-1 px-3">
                <Card className="mb-8 bg-white h1-linear-fading border-none h-full">
                  <p className="font-semibold text-xl xl:text-2xl 2xl:text-3xl 4xl:text-4xl text-primary-darker">
                    {value.title}
                  </p>
                  <p className="mt-4 text-md xl:text-lg 2xl:text-xl 4xl:text-2xl">
                    {value.content}
                  </p>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="founders" className="pt-20 pb-16 relative z-[5]">
        <div className="container w-5/6 md:4/6 mx-auto text-center">
          <h2 className="text-3xl xl:text-4xl 2xl:text-5xl 4xl:text-6xl font-bold text-primary-darkest">
            {textData.founders.title}
          </h2>
          <p
            className=" pt-4 lg:pt-8 text-md lg:text-lg xl:text-xl 3xl:text-2xl text-justify inline-block"
            dangerouslySetInnerHTML={{
              __html: textData.founders.description,
            }}
          />
          <div className="hidden lg:block">
            <div className="flex flex-col ml:flex-row sm:-mx-3 mt-12 ">
              <div className="flex-1 px-3">
                <Card className="card-bg-fading border-none mb-8 min-h-[42rem] h-full ">
                  <h2
                    className="text-xl xl:text-2xl 2xl:text-3xl 4xl:text-4xl text-primary-default font-bold pb-4"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[0].name,
                    }}
                  />
                  <p
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[0].content[0].text,
                    }}
                  />
                  <div
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[0].content[1].text,
                    }}
                  />
                  <p
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left mb-6"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[0].content[2].text,
                    }}
                  />
                </Card>
              </div>
              <div className="flex-1 px-3">
                <Card className="mb-8 min-h-[42rem] h-full ">
                  <h2
                    className="text-xl xl:text-2xl 2xl:text-3xl 4xl:text-4xl font-bold pb-4 text-primary-darker"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[1].name,
                    }}
                  />
                  <p
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[1].content[0].text,
                    }}
                  />
                  <div
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[1].content[1].text,
                    }}
                  />
                  <p
                    className="text-md xl:text-lg 2xl:text-xl 4xl:text-2xl text-left mb-6"
                    dangerouslySetInnerHTML={{
                      __html: textData.founders.list[1].content[2].text,
                    }}
                  />
                </Card>
              </div>
            </div>
          </div>
          <Accordion
            data={mapFounders}
            className="lg:hidden [&_li]:list-disc [&_li]:ml-8 [&_li]:mt-2 text-left"
          />
        </div>
      </section>
    </Layout>
  );
};

export default About;

export const Head = () => (
  <SEO
    title="Conoce todo sobre nosotros | WhyPeople"
    description="Somos la Consultora Estratégica de RR.HH con una metodología propia y soluciones personalizadas para ayudarte a construir una mejor compañía."
    keywords={["Qué es WhyPeoople"]}
  >
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
